<template>
  <vue-final-modal class="modal-deposit-status" :clickToClose="false" :focus-trap="false">
    <div class="modal-deposit-status__container">
      <span class="modal-deposit-status__close" @click="close">
        <atomic-icon id="close" />
      </span>

      <div v-if="modalStatus" class="modal-deposit-status__content">
        <div class="modal-deposit-status__top">
          <atomic-icon
            :id="modalStatus"
            :class="`modal-deposit-status__event modal-deposit-status__event_${modalStatus}`"
          />
          <div class="modal-deposit-status__info">
            <span class="modal-deposit-status__title">
              {{ depositStatusLocaleContent?.[modalStatus]?.title }}
            </span>
            <span class="modal-deposit-status__text">{{ depositStatusLocaleContent?.[modalStatus]?.text }}</span>
          </div>
        </div>

        <button-base type="primary" size="sm" class="modal-deposit-status__button" @click="close">
          {{ depositStatusLocaleContent?.buttonDesktop }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import { ModalName } from '@skeleton/consts/modals';
  import type { IDepositStatus } from '~/types';

  const { closeModal } = useModalStore();
  const route = useRoute();

  const contentParams = {
    contentKey: 'depositStatus',
    contentRoute: ['modals', 'deposit-status'],
    isPage: false,
  };

  const { getContentData } = useContentLogic<IDepositStatus>(contentParams);
  const { data: depositStatusContent } = await useLazyAsyncData(getContentData);

  const modalStatus = computed(() => {
    const status = route.query['deposit-status'];

    if (status === 'success' || status === 'failing') {
      return status;
    }

    return null;
  });

  const close = () => {
    closeModal(ModalName.DEPOSIT_STATUS);
  };

  const depositStatusLocaleContent = computed(
    () => depositStatusContent.value?.currentLocaleData ?? depositStatusContent.value?.defaultLocaleData
  );
</script>

<style src="~/assets/styles/components/modal/deposit-status.scss" lang="scss" />
